.fonts {
  font-family: 'Open Sans';
}
.label-login {
    display: flex;
    width: 432px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin: 5px;   
}

.input-login {
    --placeholder-font-style: 16px;
    --placeholder-color: var(--neutral-400, #909090);
}

.button-login {
    height: 48px;
}

.restorePasswordLink {
    cursor: pointer;
    /* color: var(--ion-color-primary) */
}

.modalContainer {
    /* width: 464px;
    height: 316.3px; */
    /* border-radius: 20px; */
    padding: 0px !important;
    /* border: #555 2px solid !important; */
    /* position: relative; */
}
  
.modalContainerImage {
    margin-top: 50.15px;
    width: 100%;
    text-align: center;
}

.modalContainerMessage {
    font-size: 24px;
    text-align: center;
    width: 100%;
    padding: 0px 64px 58.15px 64px;
}
  
.modalContainerMessage p {
    /* margin: 0px; */
}

.borderRadius-20 {
    border-radius: 20px !important;
}



.closeButtonModal {
    height: 24px;
    width: 24px;
    padding: auto;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 10.15px;
    right: 16px;
    z-index: 1000;
  }
  
.closeButtonModal:hover {
background-color: #ececec;
cursor: pointer;
}

.closeButtonModal:active {
    background-color: #C4C4C4;
}

ion-modal.exclamation {
    --width: 464px;
    --max-height: 330px;
    --border-radius: 20px;
    --background: var(--neutral-50, #FAFAFA);
    --border-color: #555 !important;
    --border-width: 2px !important;
    --box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.08), 1px 1px 4px 0px rgba(0, 0, 0, 0.08);
}

ion-modal.confirm {
    --width: 464px;
    --max-height: 200px;
    --box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.08), 1px 1px 4px 0px rgba(0, 0, 0, 0.08) !important;
    --border-radius: 20px;
    --background: var(--neutral-50, #FAFAFA) !important;
    --border-color: #555 !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal.pdfModal {
    --width: 50%;
    --height: 90%;
}

@media screen and (min-width: 767px) {
    ion-modal.loginModal {
        --border-radius: 20px;
    }
}

@media screen and (max-width: 767px) {
    ion-modal.pdfModal {
        --width: 100%;
        --height: 100%;
    }
}

.backgroundNewColor {
    /* background-color: rgba(248, 165, 105, 0.329); */
    background-color: wheat !important;
    color: red !important;
}

.widthElementAll {
    /* width: 100% !important; */
    overflow: auto;
    overflow-x: scroll;
}